import { createPortal } from 'react-dom';
import { useEffect, forwardRef, useImperativeHandle } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from '../styles/modules/Modal.module.scss';

const Modal = forwardRef(
  ({ children, title, button, action, visibility, onOpen, onClose, style }, ref) => {
    const showModal = () => {
      if (onOpen) {
        onOpen();
      }
    };
    const closeModal = () => {
      if (onClose) {
        onClose();
      }
    };
    const closeOnEscapeKeyDown = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        closeModal();
      }
    };
    const handleBackgroundClick = (e) => {
      if (e.target.className === styles.wrapper) {
        closeModal();
      }
    };

    useImperativeHandle(ref, () => ({
      show() {
        showModal();
      },
      hide() {
        closeModal();
      },
      children() {
        return children;
      }
    }));
    useEffect(() => {
      document.body.addEventListener('keydown', closeOnEscapeKeyDown);
      return () => document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    }, []);
    useEffect(() => {
      document.body.style.overflow = visibility ? 'hidden' : 'auto';
    }, [visibility]);

    if (typeof window !== 'object') {
      return null;
    }

    return createPortal(
      <AnimatePresence>
        {visibility && (
          <motion.div
            className={styles.container}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
          >
            <div className={styles.wrapper} onClick={handleBackgroundClick} style={style}>
              <motion.button
                className={styles.close}
                type="button"
                onClick={closeModal}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.5 } }}
                exit={{ opacity: 0 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </motion.button>
              <motion.div
                className={styles.modal}
                initial={window.innerWidth < 576 ? { bottom: -700 } : { opacity: 0, y: '100%' }}
                animate={
                  window.innerWidth < 576
                    ? { bottom: 0, transition: { delay: 0.2 } }
                    : {
                        opacity: 1,
                        y: '0%',
                        transition: { delay: 0.2, type: 'spring', duration: 0.5 }
                      }
                }
                exit={{ opacity: 0 }}
              >
                {title && <div className={styles.modalTitle}>{title}</div>}
                {children && <div className={styles.modalContent}>{children}</div>}
                {button && (
                  <button
                    className={[styles.modalButton, styles.fullWidth].join(' ')}
                    title="Закрыть"
                    type="button"
                    onClick={action}
                  >
                    {button}
                  </button>
                )}
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>,
      document.body
    );
  }
);

Modal.displayName = 'Modal';

export default Modal;
